@font-face {
  font-family: 'MyCustomFont';
  src: url('./ArchivoBlack-Regular.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyCustomFontBody';
  src: url('./OpenSans-Regular.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyCustomFontSemi';
  src: url('./OpenSans-SemiBold.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyCustomFontBold';
  src: url('./OpenSans-Bold.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.productsContainer {
  padding: 0px;
}

.introText {
  text-align: center;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 20px;
}


.introTitle {
  font-size: 2rem;

  color: #333;
  font-family: 'MyCustomFont';
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.introTitle2 {
  font-size: 1.5rem;
  color: #fff;
  font-family: 'MyCustomFont';
  text-align: center;

  margin-bottom: 22.5px;
}


.introPar {
  font-size: 1.2rem;
  margin-bottom: 20px;
  margin-left: 2%;
  margin-right: 2%;
  color: #555;
  font-family: 'MyCustomFontSemi';
}

.introPar3 {
  font-size: 1.25rem;
  margin-top: 10px;
  margin-bottom: 15px;

  margin-left: 0%;
  margin-right: 0%;
  color: #fff;
  font-family: 'MyCustomFontSemi';
}

.feature-desc {
  font-size: 1.5rem;
  margin-left: -30px;
  margin-right: 150px;  
  margin-top: 10px;
}
  

.introPar2 {
  font-size: 2rem;
  margin-top: 0px;
  margin-bottom: 15px;

  margin-left: 2%;
  margin-right: 2%;
  color: #000;
  font-family: 'MyCustomFontSemi';
}

.pricingOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(156deg, #EF3F3F, #000);
}

.pricingOption {
  background-color: #fff;

  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 92.5%;

  max-width: 1100px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.feature2 {
  display: flex;
  flex-direction: column;
  /* Change to column for vertical alignment */
  align-items: flex-start;
  /* Align items to the start (left) */

  margin-bottom: 10px;

  width: 100%;
  cursor: pointer;
}

.feature {
  display: flex;
  flex-direction: column;
  /* Change to column for vertical alignment */
  margin-bottom: 10px;




  cursor: pointer;
}

.feature-icon {
  margin-left: 1.5%;
  width: 100%;
  height: auto;
  max-width: 200px;
  /* Optional: Set a max width if needed */
  margin-bottom: 5px;
}






.feature-title {
  font-size: 2rem;
  font-family: 'MyCustomFontBold';
  color: #333;
  margin-left: 10px;
}

.feature-title2 {
  font-size: 3rem;
  font-family: 'MyCustomFontBold';
  color: #333;
  padding-top: 100px;
}

.feature-text {
  padding-left: 50px;
  font-size: 1.2rem;
  color: #555;
  font-family: 'MyCustomFontBody';
}

.feature-header {
  display: flex;
  align-items: center;
  width: 100%;
}

.feature-header2 {
  display: flex;
  align-items: flex-start;
  width: 100%;
}


.buttonContainer {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  /* Space below the button */
}

.feature-header .feature-title {
  margin-left: 15px;

}

.feature-text {
  font-size: 1.25rem;
  color: #333;
}

.buttonContainer {
  margin-top: auto;
}



@media (max-width: 768px) {
  .feature-icon {
    width: 40%;
    height: auto;
    max-width: 115px;
    /* Smaller width for mobile */
    margin-left: 1%;

  }



  .feature-title {
    font-size: 1.2rem;
    margin-left: -30px;
  }


.feature-desc {
  font-size: 1rem;
  margin-left: -30px;
  margin-right: 2%;
  margin-top: 5px;
}
  
  .feature-text {
    font-size: 1rem;

  }


  .introTitle2 {
    font-size: 1.8rem;
    color: #fff;
    font-family: 'MyCustomFont';
    text-align: center;

    margin-bottom: 10px;
    margin-top: -20px;
  }


  .introPar2 {
    font-size: 1.2rem;
    margin-top: 0px;
    margin-bottom: 15px;

    margin-left: 2%;
    margin-right: 2%;
    color: #000;
    font-family: 'MyCustomFontSemi';
  }

  .introTitle {
    font-size: 1.8rem;

    color: #333;
    font-family: 'MyCustomFont';
    text-align: center;
    margin-top: 20px;
    margin-bottom: 15px;
  }
}