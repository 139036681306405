body {
    background-color: black;
    align-items: center;
   
    
}

.gameWeb {
    
    
    margin-left: 1%;
    margin-right: 1%;
}

.gameMobile {
  width: 96%;
  height: 100%;
  margin-left: 1%;
  margin-right: 1%;
}





.one {
  background-color: aquamarine;
  height: 200px;
}
.two {
  color: aquamarine;
  
}


.pointsTitle {
  
  font-family:Arial, Helvetica, sans-serif;
  font-weight:bolder;
  

}

.undoButton {
  border: 2px;
  border: 2px solid green;
  background-color: white;


}

.container {
  display: grid;
  height: 100%;
  width: 100%;
  margin-left: .3rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.containerAlert {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr 1fr ;
  
}

.bottomItems{
  margin-bottom: 0%;
}

.containerBottom {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  align-items: center;
}
.vsLogo {
  width: 100%;
  max-width: 280px;
  max-height: 300px;
  height: 100%;
 
}


.scoreLogo {
  max-width: 100%;
  height: 90%;
  background-repeat: no-repeat; 
}

.bullLogoMobile {
  aspect-ratio: "1:1";
  max-width: 65%;
  max-height: 65%;
}

.bullLogo {
  aspect-ratio: "1:1";
  max-width: 810%;
  max-height: 100%;
}



.adBanner {
  width: 101%;
  max-width: 101%;
  max-height: 100%;
}

.rotButton {
  
  
  /* min-height: 15%;
  max-height: 20%; */
  background-color: grey;
  border-radius: 15%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Add any other styles you need for the container */
}

.vsText {
  font-family: 'Fantasy';
  text-transform: capitalize;
  text-shadow: 1px 1px 1px white, 3px 3px 1px white;
  text-align: center;
  letter-spacing: 2px;
  margin-top: 5%;
  color: #ff0000;
  margin-left: .3rem;
  
}

.alertBox {
  font-size: 20px;
}

.alertText {

  
  font-family:Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  text-transform: capitalize;
  text-align: center;
  font-size: xx-large;
  color: black;
}

.alertBody {

  
  margin-top: 0%;
  font-size: x-large;
  color:black;
  font-weight: bold;
}

.pointsText {

  font-family: 'Fantasy';
  text-transform: capitalize;
  text-shadow: 1px 1px 1px blue, 2px 2px 1px blue;
  text-align: center;
  letter-spacing: 1px;
  color: white;
  margin-top: 2%;
  margin-bottom: 0%;
  margin-left: 1%;
  margin-right: 1%;
}

.numberBarText {
  text-align: center;
  letter-spacing: 1px;
  color: white;
  
}

.pointsNumber {
  font-size: 4rem;
  font-family: 'Fantasy';
  text-transform: capitalize;
  text-shadow: 1px 1px 1px blue, 2px 2px 1px blue;
  text-align: center;
  letter-spacing: 1px;
  color: white;
  margin-top: 0%;
}

/* .camButton {
  max-width: 50%;
  min-height: 15%;
  max-height: 20%;

  
  margin-right: 15%;
  background-color: grey;
  border-radius: 15%;
  border-radius: 15%;
} */

.MPRTitle {
  margin-top:50% 
  
}
.barImageWeb {


  
  width: 100.3%;
  min-width: 100%;
  margin-left: 1.1%;
  margin-right: 1.1%;
  /* margin-left:.5rem;
  margin-right:.6rem; */
  
 
  
}

.barImageMobile {
  width: 101%;
  max-height: 30px;
 
  
  min-width: 100%;
  margin-left: .3rem;
  /* margin-left:.5rem;
  margin-right:.6rem; */
  

 
}

.bc {
  width: 100%;
 
  justify-content: center;
}


.pointsNumber {
  
  font-weight: bold;
}



.vsTitle {
  font-size: xxx-large;
  color: red;
}


#grid {
    
  display: grid;
   grid-auto-columns: 1fr;
   grid-auto-flow: column;
  
  }
  img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    
    }
  
  #areaScore{
    background-image: url('./icons/board.png');
    background-repeat:no-repeat;
    width: 100%;
    height: 100%;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    background-size: contain;
    
  }
  
  #areaVS {
    background-image: url('./icons/vs.jpeg');
    background-repeat: no-repeat;
    height: 200px;
    object-fit: cover;
    align-items: center;
    justify-content: center;
    justify-items: center;
    width: 5%;
    margin-left: 10%;
    margin-right: 5%
   
  }