

@font-face {
    font-family: 'MyCustomFont';
    src: url('./ArchivoBlack-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MyCustomFontBody';
    src: url('./OpenSans-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MyCustomFontSemi';
    src: url('./OpenSans-SemiBold.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

.centered-container {
    display: flex;
    justify-content: center; /* Center the entire div */
    align-items: center;
   margin-left: 5%;
   margin-right: 4.5%;
   margin-bottom: 3%;
  }
  
  .centered-image {
    width: 20%; /* Adjust the size of the image */
    height: auto;
    margin-right: 80px; /* Spacing between image and text */
    max-width: 200px;

  }

  .centered-image2 {
    width: 40%; /* Adjust the size of the image */
    height: auto;
    margin-right: 80px; /* Spacing between image and text */
    max-width: 200px;

  }
  
  .centered-text {
    text-align: left; /* Left-align the text */
    font-size:3rem;
    color: white;

    font-family: 'MyCustomFontSemi';
   
  }
  

  @media (max-width: 768px) {
    .centered-container {
        display: flex;
        justify-content: center; /* Center the entire div */
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 4%;
        
      }
      
      .centered-image {
        width: 90px; /* Adjust the size of the image */
        max-width: 90px;
        height: auto;
        margin-right: 15px; /* Spacing between image and text */
    
      }
      .centered-image2 {
        width: 85px; /* Adjust the size of the image */
        height: auto;
        margin-right: 11px; /* Spacing between image and text */
        margin-left: 2px; /* Spacing between image and text */
        max-width: 95px;
    
      }
      
      .centered-text {
        text-align: left; /* Left-align the text */
        font-size: 1.1rem;
        color: white;
        width: 100%;
        font-family: 'MyCustomFontSemi';
      }
  }