/* 01.css */

* {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome and Opera */
}

.board,
.table {
  float: left;

}

.board {

  justify-content: center;
  align-items: center;
  width:100%
 


}
.alertBox {
  font-size: 20px;
}

.buttonDiv {
  display: flex;
  justify-content: space-between; /* Puts space between the two buttons */
  width: 100%;


 
}


.whiteColor {
  color: aliceblue;
}

.table {
  width: calc(100% - 500px);
}

.board svg {

  width: 100%;

 

}

.active {
  font-size:3em; /* Increase the size */
  font-weight: bold; /* Optionally make the text bold */
}


.scoreboard {
  color: white;
  width: 100%;
}

.table table {
  border-collapse: collapse;
  float: left;
  margin: 0 0.5%;
  text-align: center;
  color: aqua;
  width: 100%;
}

.table .break {
  background: black;
}

#dartboard #areas g path,
#dartboard #areas g circle {
  cursor: pointer;
  transition: fill 0.2s ease-in-out;
}

#dartboard #areas g path:active,
#dartboard #areas g circle:active {
  fill: white;
  animation: whiteFlash 1.5s forwards;
}

@keyframes whiteFlash {
  0% {
    fill: white;
  }

  100% {
    fill: initial;
    /* Restore original color */
  }
}