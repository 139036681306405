@font-face {
  font-family: 'MyCustomFont';
  src: url('./ArchivoBlack-Regular.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyCustomFontBody';
  src: url('./OpenSans-Regular.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MyCustomFontSemi';
  src: url('./OpenSans-SemiBold.ttf') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.dartboard-and-scoreboard {
    text-align: center;
  }
  
  .new-game {
    background-color: #4F9962;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .new-game:hover {
    background-color: #3b7a53;
  }
  
  .board {
    margin-top: -2%;
    margin-bottom: -17%;
    rotate: 18%;
  }
  
  .scoreboard {
    margin-top: 0px;
  }
  
  .scoreboard table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .scoreboard th, .scoreboard td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .scoreboard th {
    background-color: #4F9962;
    color: white;
    font-family: 'MyCustomFontSemi';
    font-size: 18px;
  }
  