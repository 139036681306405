/* Background image for the page */
.background-image {
    background-image: url('./damage.jpg'); /* Set the background image here */
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 175vh; /* Set a taller background */
    background-attachment: fixed;
}

/* Card container */
.card {
    background-color: rgba(0, 0, 0, 0.7); /* Dark background for the card */
    color: white;
    padding: 30px;
    padding-top: 50px; /* Added padding to the top of the card */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    width: 70%; /* Set a specific width for the card on desktop */
    max-width: 1200px;
    margin: 20px auto; /* Center card and give it margin from top */
    text-align: center; /* Center text inside card */
    box-sizing: border-box; /* Ensure padding is inside the width */
    position: relative; /* Position relative for control */
    top: 5%; /* Adjust vertical position of the card */
}

/* Title with Light Blue color */
.themedTitle {
    font-family: 'MyCustomFontBold';
    font-size: 3rem;
    margin-block-end: 0em;
    color: white; /* Adjust title color */
}

/* Text styles */
.themedText {
    font-family: 'MyCustomFontSemi';
    font-size: 2rem;
    color: white; /* Adjust text color */
}

.themedText2 {
    font-family: 'MyCustomFontBold';
    font-size: 1.5rem;
    color: white;
    
}

.themedText3 {
    font-family: 'MyCustomFontBold';
    font-size: 1.5rem;
    color: white;
    text-align: center;
    margin-block-start: 1em;
    margin-block-end: 0em;
    padding-left: 5%;
    padding-right: 5%;
}

/* Relief container (center the content vertically and horizontally) */
.relief-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2rem;
}
.highlightText {
    color:  #ADD8E6; /* Light blue color for "RELIEF" */
  }
/* Text above the images */
.text-above {
    text-align: center;
    color: white;
    margin-bottom: 20px;
    padding-left: 10%;
    padding-right: 10%;
}

/* Image styling */
.relief-images {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap; /* Allow images to wrap on smaller screens */
}

.relief-image {
    width: 30%; /* Keep images at 30% of the container's width on desktop */
    padding: 1rem; /* Added padding between images */
    height: auto; /* Maintain aspect ratio */
}

/* Media query for mobile screens (768px or less) */
@media (max-width: 768px) {
    .background-image {
        height: 250vh; /* Adjust background size on mobile */
    }

    .card {
        width: 90%; /* Adjust card width for mobile */
        padding-right: 30%;
        padding: 15px; /* Adjust padding on mobile */
        padding-top: 20px; /* Added padding-top for mobile */
        margin: 0 auto; /* Center the card */
        top: 5%; /* Adjust card's vertical position on mobile */
        right: 8%;
    }

    .relief-images {
        flex-direction: column; /* Stack images vertically */
        justify-content: center; /* Center the images vertically */
        align-items: center; /* Ensure images are centered */
    }

    .relief-image {
        width: 80%; /* Make each image 80% of the container's width on mobile */
        margin-bottom: 20px; /* Add space between the images */
    }

    /* Title with Light Blue color */
.themedTitle {
    font-family: 'MyCustomFontBold';
    font-size: 2rem;
    margin-block-end: 1em;
    color: white; /* Adjust title color */
}
.highlightText {
  color:  #ADD8E6; /* Light blue color for "RELIEF" */
}
/* Text styles */
.themedText {
    font-family: 'MyCustomFontSemi';
    font-size: 1.25rem;
    color: white; /* Adjust text color */
}

.themedText2 {
    font-family: 'MyCustomFontBold';
    font-size: 1.25rem;
    margin-block-start: 2em;
    margin-block-end: 1.5em;
    color: white;
   
}

.themedText3 {
    font-family: 'MyCustomFontBold';
    font-size: 1.25rem;
    color: white;
    text-align: center;
    margin-block-start: 1em;
    margin-block-end: 0em;
    padding-left: 5%;
    padding-right: 5%;
}

}
