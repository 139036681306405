@font-face {
    font-family: 'MyCustomFont';
    src: url('./ArchivoBlack-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MyCustomFontBody';
    src: url('./OpenSans-Regular.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'MyCustomFontSemi';
    src: url('./OpenSans-SemiBold.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'MyCustomFontBold';
    src: url('./OpenSans-Bold.ttf') format('woff2');
    font-weight: normal;
    font-style: normal;
  }


/* CenteredDiv3.css */
.grid-container {
    display: grid;
    gap: 20px;
    padding: 20px;
    margin-bottom: 5%;
    justify-content: center;

}

.caption {
    margin-top: 10px;
    font-size: 1.5rem;
    font-family: 'MyCustomFontBold';
}

.grid-item {
    background-color: white;
    border-radius: 10px;
    text-align: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    min-height: 350px;
    max-height: 350px;
    /* Ensures padding and border are included in the total width */
}

.grid-item2 {
    background-color: white;
    border-radius: 10px;
    text-align: center;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    min-height: 350px;
    max-height: 350px;
    margin-Top: -11px;
    margin-bottom: 5px;
    /* Ensures padding and border are included in the total width */
}


.grid-image2 {
    max-width: 120%;
    min-width: 200px;
    height: auto;
    border-radius: 10px;
    margin-top: 15px;
    margin-bottom: 10px;
}

.grid-item-tab {
    width: 100%;
    /* Items fill available space */

    /* Optional: Max width to keep a good layout */
}

/* Grid layout for larger screens (over 768px) */
@media (min-width: 550px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
        justify-content: center;
     
        /* 2 rows of 3 */
    }

    .grid-item {

        /* Items will fill the available space */
        font-size: 30px;

    }



    .grid-image {
        max-width: 100%;
        max-height: 200px;
        border-radius: 10px;
    }

    .grid-imageBoard {
        max-width: 100%;
        max-width: 250px;
        height: auto;
        border-radius: 10px;
        margin-top: 15px;
        margin-bottom: 5px;
    }

    .grid-imageTab {
        max-width: 100%;
        max-width: 250px;
        height: auto;
        border-radius: 10px;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    .grid-imageBack {

        max-width: 100%;
        max-width: 250px;
        height: auto;
        border-radius: 10px;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .grid-imageSoft {
        max-width: 100%;
        max-width: 270px;
        height: auto;
        border-radius: 10px;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .grid-imageStrip {

        max-width: 100%;
        max-width: 250px;
        height: auto;
        border-radius: 10px;
        margin-top: 5px;
        margin-bottom: -5px;

    }

    .grid-imageDart {

        max-width: 100%;
        max-width: 240px;
        height: auto;
        border-radius: 10px;
        margin-top: 20px;
        margin-bottom: -10px;

    }

}

/* Grid layout for mobile screens (under 768px) */
@media (max-width: 550px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;
        justify-items: center;  /* Center grid items horizontally */
        align-items: center; 
        margin-bottom: 40px;
  
        /* 3 rows of 2 */
    }

    .caption {
        margin-top: 10px;
        font-size: 15px;
        font-family: 'MyCustomFontBold';
        color: black;
    }


    .grid-item {
        background-color: white;
        border-radius: 10px;
       

        margin-bottom: 0px;
            
        /* Items fill available space */
        min-width: 160px;
        max-width: 160px;
        min-height: 200px;
        max-height: 250px;
        margin-top: 30px;
   

        /* Ensures padding and border are included in the total width */
    }
    

    .grid-itemLast3{
        background-color: white;
        border-radius: 10px;
       


            
        /* Items fill available space */
        min-width: 160px;
        max-width: 160px;
        min-height: 200px;
        max-height: 250px;
        margin-top: 20px;
   

        /* Ensures padding and border are included in the total width */
    }
    

    .grid-imageBoard {

        max-width:130px;
      
        border-radius: 20px;
        margin-top:0px;
        margin-bottom: 0px;
    }

    .grid-imageTab {
        max-width: 127px;
      
        height: auto;
        margin-bottom: 0px;
        border-radius: 0px;
   
    }

    .grid-imageBack {

        max-width: 130px;
      
        height: auto;
        margin-bottom: -4px;
        border-radius: 0px;
    }

    .grid-imageSoft {
      
        max-width: 145px;
      
        height: auto;
        margin-bottom: 0px;
        border-radius: 0px;

    }

    .grid-imageStrip {

        max-width: 140px;
      
        height: auto;
        margin-top: -10px;
        margin-bottom: -16px;
        border-radius: 0px;

    }

    .grid-imageDart {

        max-width: 125px;
      
        height: auto;
        margin-top: -2px;
        margin-bottom: -10px;
        border-radius: 0px;

    }

    .caption {
        font-family: 'MyCustomFontBold';
        font-size: .9rem;
        color: black;
    
    }

    .grid-item2 {

        border-radius: 10px;
        min-width: 160px;
        max-width: 160px;
        min-height: 200px;
        max-height: 250px;

        margin-Top: 28px;
        margin-bottom: 2px;

        /* Ensures padding and border are included in the total width */
    }
    
}

.grid-image {
    max-width: 100%;

    border-radius: 10px;
}

